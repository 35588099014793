import React from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import requestHandler from "../../../actions/httpClient";

const Successfully = ({
  setVisibleNotificationModal,
  accountStatus
}) => {

  const logOutHandler = async () => {
    const data = {
      signature: localStorage.getItem('signature')
    }
    try {
      const logoutPayload = await requestHandler('logout', 'post', data, 'jwt_token')
      if (logoutPayload?.status === 200) {
        window.location.href = process.env.REACT_APP_GLOBAL_URL;
        deleteAllCookies();
        localStorage.removeItem('signature')
        localStorage.removeItem('jwt_token')
        localStorage.removeItem('switch_exchange')

      }
    }
    catch (error) {
      window.location.href = process.env.REACT_APP_GLOBAL_URL;
    }
  };

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h4", styles.title)}>
          Create Bitdenex International Account
        </div>
        <div className={styles.boxModal}>
          <div className={styles.mainContent}>
            <p>
              Due to regulatory (MiCa) changes, Bitdenex is obliged to disable the "Switch Account" feature. When you would like to use the Bitdenex International services, a new account is required.
            </p>
          </div>
        </div>
        {
          parseInt(accountStatus) === parseInt(process.env.REACT_APP_LINKED_ACCOUNT_STATUS_APPROVED) ?
            <div className={styles.messageContainer}>
              <div className={styles.messageBox}>
                <p>Your Bitdenex International account is approved and linked with your personal data (KYC).</p>
              </div>
              <div className={styles.instructionsBox}>
                <p>Please log in with your new credentials (new email address and password) on our web application.</p>
              </div>
              <div className={styles.supportBox}>
                <p>For any support, please email us at Support@bitdenex.com</p>
              </div>
              <div className={styles.noteContainer}>
                <p>
                  <strong>Note:</strong> By clicking this button, you will be signed out of your current account and redirected to the sign-in page to complete the login process with your Bitdenex International credentials.
                </p>
              </div>
            </div>
            :
            <div className={styles.messageContainer}>
              <div className={styles.messageBox}>
                Awaiting approval from Bitdenex Compliance team. Your request will be processed within 24 hours. Thank you!
              </div>
            </div>
        }
        {/* <div className={cn("h6", styles.title)}>
          *Additional local T&C's may apply!
        </div> */}
        {
          parseInt(accountStatus) === parseInt(process.env.REACT_APP_LINKED_ACCOUNT_STATUS_APPROVED) ?
            <div className={styles.btns}>
              <button
                className={cn("button-small button-green", styles.button)}
                onClick={(e) => {
                  e.preventDefault();
                  // setVisibleNotificationModal(false);
                  logOutHandler();
                }}
              >
                Sign in
              </button>
            </div>
            :
            <div className={styles.btns}>
              <button
                className={cn("button-small button-green", styles.button)}
                onClick={(e) => {
                  e.preventDefault();
                  setVisibleNotificationModal(false);
                }}
              >
                Ok
              </button>
            </div>
        }
      </div>
    </>
  );
};

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export default Successfully;
