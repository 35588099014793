import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Successfully.module.sass";
import { firstLetterCapitalHandler } from "../../helper";
import TextInput from "../../TextInput";
import SimpleReactValidator from "simple-react-validator";
import { addNotification } from "../../Notification";

const Successfully = ({
  userSwitchRegion,
  userStatus,
  setVisibleModal,
  linkAccountRequestHandler
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      password: "Password must contain at least one uppercase letter and one special character!",
    },
    validators: {
      password: {
        rule: (value) => {
          const uppercasePattern = /[A-Z]/;
          const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
          return uppercasePattern.test(value) && specialCharPattern.test(value);
        },
        message: "Password must contain at least one uppercase letter and one special character!",
        required: true,
      },
    },
  }));
  const [forceUpdate, setForceUpdate] = useState();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    };
    setPasswordType("password")
  };

  return (
    <>
      <div className={styles.successfully}>
        <div className={cn("h4", styles.title)}>
          {/* Switch to Bitdenex {firstLetterCapitalHandler(userStatus?.exchangeName)} Exchange! */}
          Create Bitdenex International Account
        </div>
        {/* <div className={cn("h5", styles.subTitle)}>
          Are you sure you want to switch account?
        </div>
        <div className={cn("h6", styles.title)}>
          *Additional local T&C's may apply!
        </div> */}
        <div className={styles.boxModal}>
          <div className={styles.mainContent}>
            <p>
              Due to regulatory (MiCa) changes, Bitdenex is obliged to disable the "Switch Account" feature. When you would like to use the Bitdenex International services, a new account is required.
            </p>
            <p>
              All your funds and history will be transferred to your new Bitdenex International account by following the steps.
            </p>
          </div>
          <div className={styles.keyPointsBox}>
            <h3 className={styles.title}>Necessary requirements:</h3>
            <ul className={styles.list}>
              <li>A new email address is required which is not used for any Bitdenex account.</li>
              <li>A new password is required.</li>
            </ul>
          </div>
        </div>

        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            type="email"
            placeholder="Please enter email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onBlur={() => {
              simpleValidator.current.showMessageFor("amount");
            }}
          />
        </div>
        <span className={styles.danger}>
          {simpleValidator.current.message("email", email, "required|email")}
        </span>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="Password"
            name="password"
            type={passwordType}
            placeholder="Please enter password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            view
            togglePassword={togglePassword}
            onBlur={() => {
              simpleValidator.current.showMessageFor("password");
            }}
          />
        </div>
        <span className={styles.danger}>
          {simpleValidator.current.message("password", password, "required|min:9")}
        </span>
        <div className={styles.box}>
          <TextInput
            className={styles.field}
            label="confirm password"
            name="confirm-password"
            type="password"
            placeholder="Password"
            value={confirmPassword}
            onChange={(e) => { setConfirmPassword(e.target.value); }}
            onBlur={() => { simpleValidator.current.showMessageFor("confirm-password") }}
          />
          <span className={styles.danger}>
            {simpleValidator.current.message("confirm password", confirmPassword, `required|in:${password}`)}
          </span>
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-small button-red", styles.button)}
            onClick={(e) => {
              e.preventDefault();
              setVisibleModal(false);
            }}
          >
            No
          </button>
          <button
            className={cn("button-small button-green", styles.button)}
            onClick={(e) => {
              if (simpleValidator.current.allValid()) {
                e.preventDefault();
                // userSwitchRegion();
                if (userStatus?.email !== email) {
                  linkAccountRequestHandler(email, password, confirmPassword);
                }
                else {
                  addNotification({
                    title: "Alert",
                    message: "You can not link this email, because this email is associated with your account.",
                    type: "danger",
                  });
                }
              }
              else {
                simpleValidator.current.showMessages();
                setForceUpdate(forceUpdate + 1);
              }
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default Successfully;
