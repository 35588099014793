import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./BankDetails.module.sass";
import Table from "../Table";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import Radio from "../../../components/Radio";
import { addNotification } from "../../../components/Notification";
import CustomDropdown from "../../../components/CustomDropdown";
import BankSkeleton from "../../../components/Skeleton/BankSkeleton";

const BankDetails = ({
  loading,
  bankList,
  userStatus,
  bankDetailsData,
  gateWayList,
  primaryBankHandler,
}) => {

  const [paymentMode, setPaymentMode] = useState("Select a value");
  const [text, setText] = useState('Select a value');
  const [textIssuer, setTextIssuer] = useState('Select a value');
  const [bankIssuer, setBankIssuer] = useState("Select a value");
  const [account, setAccount] = useState('');
  const [bankProof, setBankProof] = useState();
  const [onlineBanking, setOnlineBanking] = useState(false);
  const [paymentTypeValue, setPaymentTypeValue] = useState();
  const [property, setProperty] = useState()
  const [selected, setSelected] = useState([])
  const [option, setOption] = useState();
  const [option2, setOption2] = useState([]);

  useEffect(() => {
    gateWayList.map(x => {
      if (x.gateway_name == "SEPA") {
        setPaymentTypeValue(x.id)
      }
    })
  }, [gateWayList])

  useEffect(() => {
    if (paymentMode) {
      selected.map((x, index) => {
        if (x.issuers && x.id == paymentMode) {
          let filteredData = x.issuers.map((y, index) => { return { "cId": y.id, "cValue": y.name?.toUpperCase() } })
          setOption2(filteredData);
        } else if (!x.issuers && x.id == paymentMode) {
          setOption2([]);
        }
      })
    }
  }, [paymentMode, bankIssuer]);

  const handleSepa = (x, index) => {
    setProperty(index)
    setPaymentTypeValue(x.id);
    setOnlineBanking(false)
  }

  const handleRadio = (x, index) => {
    setProperty(index)
    setSelected(x.services)
    let filteredData = x.services.map((x, index) => { return { "cId": x.id, "cValue": x.name?.toUpperCase(), 'index': index } })
    setOption(filteredData);
    setPaymentTypeValue(x.id);
    setText('Select a value ')
    setPaymentMode(null)
    setBankIssuer(null)
    setTextIssuer('Select a value')
    setOnlineBanking(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!onlineBanking) {
      if (account != null && bankProof != null) {
        bankDetailsData(paymentTypeValue, account, bankProof,);
      }
      else {
        addNotification({
          title: "Alert",
          message: "Please fill all the fields.",
          type: "Danger"
        });
      }
    } else if (onlineBanking) {
      if (paymentMode != "select a value" && paymentMode !== null && account != null && bankProof != null && bankIssuer !== null) {
        bankDetailsData(paymentTypeValue, account, bankProof, paymentMode, text, bankIssuer);
      } else if (paymentMode !== 'ideal' && account != null && bankProof != null) {
        bankDetailsData(paymentTypeValue, account, bankProof, paymentMode, text);
      }

      else {
        addNotification({
          title: "Alert",
          message: "Please fill all the fields.",
          type: "Danger"
        });
      }
    }
  }

  return (
    <>
      {userStatus?.add_more_accounts && (
        <div className={styles.wrap} key={1}>
          <div className={styles.title} key={2}>Use your bank account from which you want to deposit and withdraw EUR.</div>
          <div className={styles.info} key={3}> Maximum bank accounts you can add : {userStatus.max_bank_account_no}</div>
          {loading ? <BankSkeleton count={3} height={30} />
            :
            gateWayList?.map((x, index) => {
              if (x.gateway_name === 'SEPA') {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col}>
                      <Checkbox
                        className={styles.checkbox}
                        content={x.gateway_name}
                        value={true}
                        onChange={() => handleSepa(x, index)}
                        key={index}
                      //by giving same name it toggles the value or select only 1 value at a time...research by himanshu
                      />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className={styles.row} key={index}>
                    <div className={styles.col} >
                      <Radio
                        className={styles.checkbox}
                        content={x.status !== 1 ? x.gateway_name + ' <small>(Gateway not available )</small>' : x.gateway_name}
                        value={property == index ? true : false}
                        disabled={x.status === 1 ? false : true}
                        onChange={() => handleRadio(x, index)}
                        optionTwo
                        name={'sameName'}
                        key={index} />
                    </div>
                  </div>
                )
              }
            })}

          {onlineBanking && <div className={styles.row}>
            <div className={styles.col} key={option.cID}>
              <CustomDropdown
                className={styles.dropdown}
                label="Select Service"
                value={paymentMode}
                setValue={setPaymentMode}
                text={text}
                setText={setText}
                options={option}
                key={option.cID}

              />
            </div>

            {(option2?.length != 0 && paymentMode) ?
              <div className={styles.col} key={option2.cID}>
                <CustomDropdown
                  label="Select your bank?"
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={bankIssuer}
                  setValue={setBankIssuer}
                  text={textIssuer}
                  setText={setTextIssuer}
                  options={option2}
                  key={option2.cID}
                />
              </div> : null}
          </div>}

          <div className={styles.row}>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Account number"
                name="account-number"
                type="text"
                required
                placeholder="Enter your account number."
                value={account}
                // onChange={(e) => { setAccount(e.target.value) }}
                onChange={(e) => {
                  const re = /^[A-Za-z0-9]*$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setAccount(e.target.value)
                  }
                }}
              />
            </div>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                classLabel={styles.label}
                classInput={styles.input}
                label="Proof of bank account"
                name="bank-proof"
                type="file"
                required
                onChange={e => setBankProof(e.target.files[0])}
              />
            </div>
          </div>
          <div className={styles.stage}>Note: Please enter an account number in <span className={styles.bankText}>
            {(text.toLowerCase() === "bancontact") ? "BExx-xxxx-xxxx-xxxx" : (text.toLowerCase() === "giropay") ? "DExx-xxxx-xxxx-xxxx-xxxx-xx" : "NLxx-xxxx-xxxx-xxxx-xx"}
          </span> format.</div>

          {onlineBanking && <div className={styles.stage}>
            Your account will be charged with 1 cent. After successful verification the Deposit and Withdraw options for Euro will be
            enabled.
          </div>}
          <button disabled={loading} className={cn("button", styles.button)} onClick={(e) => handleSubmit(e)}>
            {onlineBanking ? "Verify Bank Account" : "Continue with SEPA only"}
          </button>
        </div>
      )}

      <br />
      <div className={styles.wrap}>
        <Table
          bankList={bankList}
          loading={loading}
          primaryBankHandler={primaryBankHandler}
        />
      </div>
    </>
  );
};

export default BankDetails;
